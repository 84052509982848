import React from 'react'
import { SRLWrapper } from "simple-react-lightbox"
import { getSrc, getSrcSet } from "gatsby-plugin-image"

const Gallery = (props) => {
    return (
        <>
        <SRLWrapper>
            <div className="flex flex-wrap -m-4 justify-center">
            {props.gallery.map((item, index) => {
                const imageSrc = getSrc(item.galleryimage)
                const imagesSrcSet = getSrcSet(item.galleryimage)
                return (
                <div className="lg:w-1/2 sm:w-1/2 cursor-pointer" style={{minHeight: "300px"}} key={index}>
                    <div className="h-96 grid" >
                        <img src={imageSrc} srcSet={imagesSrcSet} alt="" style={{aspectRatio: "3 / 1", gridArea: "1/1",  objectFit: "cover", width: "100%", height: "100%"}} className="row-start-1 col-start-1 hover:opacity-20 z-10 transition duration-500 ease-in-out"/>
                        <div className="row-start-1 col-start-1 px-8 flex items-center w-full relative bg-template-accent opacity-80 transition duration-500 ease-in-out" srl_overlay="true">
                            <h2 className="font-cursive text-lg font-medium text-white mb-3">{item.gallerytitle}</h2>
                        </div>
                    </div>
                </div>               
                )})}
            </div>
        </SRLWrapper>
        </>
    )
}

export default Gallery