// Node Modules
import React from "react";
import { graphql, Link } from "gatsby";
import SimpleReactLightbox from 'simple-react-lightbox'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'

// Components
import Layout from "../components/Layout/Layout";
import Seo from '../components/Seo'
import Gallery from "../components/Gallery"
import { GatsbyImage } from "gatsby-plugin-image";

// Assets
import bgGrass from '../images/background-header-grass-70.svg'

const AccommodationPage = ({ data }) => {
  const page = data.pagedata;
  const settingsgeneral = data.settingsgeneral;

  let events = []
  data.bookings.edges.map(booking => {
    const start = new Date(booking.node.frontmatter.start.replace(/-/g, "/"))
    const end = new Date(booking.node.frontmatter.end.replace(/-/g, "/")) 
    return (
        events.push({'title':'Booked', 'start': start, 'end': end})
    )       
  })
  const localizer = momentLocalizer(moment)

  const divText = {
    gridArea: "1/1",
    position: "relative",
    placeItems: "end",
    display: "grid"
  };

  return (
    <>
      <Layout>
        <Seo strTitle={page.frontmatter.seotitle} strDescription={page.frontmatter.seometadesc} strImage={page.frontmatter.featuredimage.publicURL} />
        <div className="h-80 w-full md:h-425 lg:h-465 xl:h-515" style={{backgroundImage: `url(${bgGrass})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'bottom'}}>
            <div className="grid h-full relative overflow-hidden">
                <GatsbyImage image={page.frontmatter.featuredimage.childImageSharp.gatsbyImageData} alt={page.frontmatter.featuredimagealt}
                  className="row-start-1 col-start-1" style={{maxHeight: "515px", zIndex: "-10"}}
                  layout="fullWidth"
                  aspectratio={3 / 1}
                />
            </div>
        </div>
        <div className="">
            <div className="container mx-auto">
                <div style={divText} className="flex justify-around">
                    <div className="text-center font-cursive max-w-screen-lg">
                        <h1 className="text-template-accent tracking-wider text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-normal py-3">{page.frontmatter.title}</h1>
                    </div>
                </div>
                <div className="flex justify-around py-6 px-2">
                    <div className="max-w-screen-lg cmscontent" dangerouslySetInnerHTML={{ __html: page.html}}>
                    </div>
                </div>
                {/* Calendar */}
                <div className="container mx-auto">
                    <div className="cmscontent flex justify-around"><h2>{settingsgeneral.childMarkdownRemark.frontmatter.settingheadercalendartxt}</h2></div>
                </div>
                <div className="container mx-auto">
                  <div className="flex justify-around px-2">
                      <div className="max-w-screen-lg w-full flex justify-around px-2 flex-col md:flex-row">
                          <div className="hidden md:w-1/2 m-6 bg-black md:flex flex-wrap content-center" style={{backgroundImage: `url(${page.frontmatter.featuredimage.publicURL})`, backgroundSize: "cover"}}>
                              <div className="w-full text-center text-white font-cursive text-2xl">{page.frontmatter.title}</div>
                          </div>
                          <div className="py-6 md:pr-6 w-full md:w-1/2">
                              <Calendar
                                  eventPropGetter={
                                      (event, start, end, isSelected) => {
                                      let newStyle = {
                                        backgroundColor: "#6Ba4B8",
                                        color: 'white',
                                        borderRadius: "0px",
                                        border: "none",
                                        paddingBottom: "20px",
                                        fontSize: "0.7rem"
                                      };
                                      return {
                                          className: "",
                                          style: newStyle
                                      };
                                      }
                                  }
                                  selectable="ignoreEvents"
                                  localizer={localizer}
                                  events={events}
                                  startAccessor="start"
                                  endAccessor="end"
                                  views={['month']}
                              />
                          </div>
                      </div>
                  </div>
              </div>
              <div className="container mx-auto flex justify-around mb-6">
                <div className="py-3 px-6 bg-template-accent text-white mx-2">
                    <Link to="/contact/">
                        Contact Us to Book
                    </Link>
                </div>
              </div>
                {/* End Calendar */}
                <div className="flex justify-around py-6 px-2 row-start-2 col-start-1">
                    <div className="max-w-screen-lg overflow-hidden">
                        <SimpleReactLightbox>
                            <Gallery gallery={page.frontmatter.gallery} />
                        </SimpleReactLightbox>
                    </div>
                </div>
            </div>
        </div>
      </Layout>
    </>
  );
};

export default AccommodationPage;

export const query = graphql`
  query ($slug: String!, $accom: String) {
    pagedata: markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      html
      frontmatter {
        title
        featuredimage {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              layout: FULL_WIDTH
            )
          }
          ext
          publicURL
        }
        featuredimagealt
        gallery {
            galleryimage {
              childImageSharp {
                gatsbyImageData(
                    placeholder: BLURRED,
                    formats: [AUTO, WEBP]
                    transformOptions: {fit: COVER, cropFocus: CENTER}
                    )
              }
              publicURL
              id
            }
            gallerytitle
          }
        seotitle
        seometadesc
      }
    }
    bookings: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(bookings)/.*[.]md$/"}, frontmatter: {accomodation: {eq: $accom}}}) {
      edges {
        node {
          frontmatter {
            accomodation
            start
            end
          }
        }
      }
    }
    settingsgeneral: file(relativePath: { eq: "settings/general.md" }) {
      childMarkdownRemark {
          frontmatter {
              settingheadercalendartxt
          }
      }
  }
  }
`;
